import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "医師も Github にコミット！メドレーの目指す「全員で行う高速開発体制」について 〜非エンジニア向け勉強会(Git 編)〜",
  "date": "2016-11-02T03:29:44.000Z",
  "slug": "entry/2016/11/02/122944",
  "tags": ["medley"],
  "hero": "./2016_11_02.png",
  "heroAlt": "git"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`の開発担当エンジニア 兼 `}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/31064"
      }}>{`プロダーツプレイヤー`}</a>{`の徐 聖博です。`}</p>
    <p>{`メドレーでは定期的に技術勉強会を行っているのですが、この勉強会に参加するのはエンジニアばかりとは限りません。先日は`}<strong parentName="p">{`非エンジニア向けに Git の勉強会を開催`}</strong>{`しました。エンジニアではないメンバーも Git を使えるようにすることは、プロダクトの高速開発に大きな効果があると僕たちは考えています。今回は、その思想の背景と、実際に行った勉強会の内容についてご紹介します。`}</p>
    <h1>{`一般的な開発フロー`}</h1>
    <p>{`実際にサービスをコードレベルで把握・修正する人は大抵の場合エンジニア（一部デザイナー）と呼ばれる人種です。 しかし、一般的に、「サービスの運営」と一言で言っても、そこに携わる人の役割は様々です。 サービスの規模にもよりますが、ディレクターやエンジニア・デザイナーはもとより法務や広報など様々な方のサポートによって一つのサービスが成り立ちます。`}</p>
    <p>{`こうした人が「使いにくい」「ここ、なんか文章間違ってる」と思った場合どうなるでしょうか。 通常は、ディレクターが意見をまとめて方針を決め、一度エンジニアと相談した上で、実装・確認の後リリースというような開発フローを経ます。（下図）`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20161031/20161031105325.png",
        "alt": null
      }}></img></p>
    <p>{`これでは改善をしようと思っていても、実際に実装が終わり改善されるまでに時間がかかりすぎてしまいます。`}</p>
    <h1>{`メドレーが目指す開発の形`}</h1>
    <p>{`メドレーでは、こうした通常の開発における課題解決や、エンジニアリング技術を使った業務改善を目指し、定期的に非エンジニア・デザイナー向けの勉強会を開いています。`}</p>
    <p>{`今回、`}<strong parentName="p">{`初回として行ったのは非エンジニア向けの Git 講座`}</strong>{`です。
メドレーでは、先ほど述べたような問題を解決しリリースまでの時間を短縮できる開発フローを目指しており、その一環としてこのような勉強会を開きました。
下図のように、誤りを発見した人が直接自分で編集し、エンジニアがプルリクをレビューしてマージ・リリースすることで開発速度の向上・品質の担保を目指しています。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20161031/20161031105322.png",
        "alt": null
      }}></img></p>
    <p>{`※勉強会で実際に使った資料はこちら`}</p>
    <iframe id="talk_frame_361652" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/bfce6b87e40d47eeb38e1238e82e8efa" width="710" height="596" frameBorder="0" allowFullScreen="true"></iframe>
    <cite className="hatena-citation"><a href="https://speakerdeck.com/medley/di-1hui-medorefei-enziniaxiang-kemian-qiang-hui-fei-enziniafalsetamefalsezui-di-xian-falsegitcao-zuo">speakerdeck.com</a></cite>
    <p>{`メドレーの`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Github"
      }}>{`Github`}</a>{`には、エンジニア・ディレクターに加えて医師や営業といった様々な職種の方が開発者登録されており、全員で直接コードを編集し、日々プロダクト改善に努めています。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20161102/20161102110814.png",
        "alt": null
      }}></img></p>
    <h1>{`まとめ`}</h1>
    <p>{`メドレーでは、課題解決・効率化を目指して、さまざまな勉強会を開催しています。非エンジニア・デザイナーに向けても、Git 講座を皮切りに今後月 1 で勉強会を開催予定です。 こうした勉強会の様子を定期的に記事にしていくので、ご興味ある方はメドレーブログをフォローいただけたらなと思っております。`}</p>
    <p>{`※`}<a parentName="p" {...{
        "href": "https://www.facebook.com/medley.jp/"
      }}>{`メドレー公式 Facebook ページ`}</a>{`に「いいね！」していただけると、ブログの最新情報をフォローできます`}</p>
    <p>{`今後とも、よろしくお願いします！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      